<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('レビューリサーチ') }}</manual-link>

      <div class="max-w-md d-flex flex-column m-2">
        <div class="search input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
              </svg>
            </span>
          </div>
          <input type="text" class="form-control py-4" :placeholder="t('ASINコード')" :aria-label="t('ASINコード')" aria-describedby="search-product" v-model="asin" @keydown.enter="search" />
          <div class="input-group-append ratingpane">
            <b-form-rating v-model="rating" color="orange" class="rating" :show-clear="true"></b-form-rating>
          </div>
          <div class="input-group-append">
            <b-button class="btn bg-eresa text-white px-3" type="button" @click="search">{{ t('検索') }}</b-button>
          </div>
        </div>
      </div>
    </div>

    <b-alert :show="showMessege && messageType === MESSAGE_NOTFOND" variant="info">{{ t('{0}の検索に一致する商品はありませんでした。', [lastAsin]) }}</b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_TIMEOUT" variant="info">{{ t('検索が失敗しました。再度検索を試してください。※アクセスが集中するピーク時間帯には検索が失敗する場合がありますので、何度か試みても問題が解決されない場合は、時間を空けてから再度お試しください。') }}</b-alert>

    <div v-if="reviews.length > 0" class="container">
      <div class="d-flex productinfo">
        <div>
          <img width="48" :src="`${product.image}`" />
        </div>
        <a role="button" rel="noreferrer" target="_blank" :href="`${product.link}`" class="text-eresa ml-2" style="text-decoration: underline">
          {{ product.title }}
          <b-icon icon="box-arrow-up-right" class="ml-2"></b-icon>
        </a>
      </div>
      <div class="d-flex mt-2 mb-4">
        <b-form-rating :value="summary.rating" color="orange" class="rating" readonly="true" show-value="true" size="sm"></b-form-rating>
        <div class="count">({{ summary.reviews_total }})</div>
      </div>

      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" size="sm" hide-goto-end-buttons></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" hide-goto-end-buttons></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
        </div>
      </div>
    </div>

    <div v-if="reviews.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <review-list ref="resultList" :key="resultListKey" :reviews="reviews" :domainId="lastDomain" @loaded="loaded"></review-list>
        </div>
      </div>
    </div>

    <div v-if="reviews.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" size="sm" hide-goto-end-buttons></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="rfpagination.lastpage" :per-page="1" class="pagination-eresa" hide-goto-end-buttons></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, rfpagination.lastpage]) }}</div>
      </div>
    </div>

    <Loading v-else-if="loading"></Loading>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import ManualLink from '@/components/ManualLink.vue';
import ReviewList from '@/components/ReviewList.vue'
import Loading from '@/components/Loading.vue';
import AiChatWindow from '@/components/AiChatWindow';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { AIChat } from '@/mixins/AIChat';

const MANUAL_JP = 'https://pro.eresa.jp/function/review-research/';
const MANUAL_COM = 'https://eresa.io/function/review-research/';

const MESSAGE_NOTFOND = 0;
const MESSAGE_TIMEOUT = 1;

export default {
  name: 'SellerResearch',
  components: {
    ManualLink,
    ReviewList,
    Loading,
    AiChatWindow,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      MESSAGE_NOTFOND,
      MESSAGE_TIMEOUT,
      page: 1,
      rfpagination: {
        lastpage: 1,
      },
      disabledAiAnalysis: true, // AI分析非活性フラグ
      asin: '',
      rating: null,
      product: null,
      summary: null,
      reviews: [],
      showMessege: false,
      messageType: MESSAGE_NOTFOND,
      resultListKey: 0,
      loading: false,
      lastAsin: null,
      lastRating: null,
      lastDomain: null,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async created() {
    if (this.$route.query.domain != void 0) {
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit('setDomainCom', isCom);
    }

    this.asin = this.$route.params.asin ?? '';
  },
  async mounted() {
    if (this.asin != void 0 && this.asin !== '') {
      this.search();
    }
    await this.validateSubscriber();
  },
  computed: {
  },
  watch: {
    async page() {
      if (this.lastDomain != void 0) {
        this.$store.commit('setDomain', this.lastDomain);
      }
      if (this.asin != this.lastAsin) {
        this.asin = this.lastAsin;
      }
      if (this.rating != this.lastRating) {
        this.rating = this.lastRating;
      }
      await this.showList();
    },
  },
  methods: {
    async search() {
      if(this.asin == void 0 || this.asin === '') {
        console.info('input empty');
        return;
      }

      this.showMessege = false;
      this.messageType = MESSAGE_NOTFOND;
      this.disabledAiAnalysis = true;
      this.page = 1;
      this.resultListKey++;
      this.lastAsin = this.asin;
      this.lastRating = this.rating;
      this.lastDomain = this.$store.getters.getDomain;
      this.showList();
    },
    async showList() {
      this.disabledAiAnalysis = true;
      this.product = null;
      this.summary = null;
      this.reviews = [];

      this.loading = true;
      try {
        const res = await this.getReviews(this.lastDomain, this.lastAsin, this.lastRating, this.page);
        if (res == void 0 || res.reviews == void 0 || res.reviews.length <= 0) {
          this.showMessege = true;
          return;
        }
        this.product = res.product;
        this.summary = res.summary;
        this.reviews.push(...res.reviews);
        this.makeRainforestPagination(res);
      }
      catch(e) {
        if (e.errors != void 0 && e.errors[0].errorType === 'ExecutionTimeout') {
          this.messageType = MESSAGE_TIMEOUT;
          this.showMessege = true;
          return;
        }
        throw e;
      }
      finally {
        this.loading = false;
      }
    },
    async getReviews(domain, asin, rating, page) {
      const rslt = await API.graphql(
        graphqlOperation(queries.getReviews, { domain, asin, rating, page })
      );
      const res = JSON.parse(rslt.data.getReviews);
      if (!res) {
        this.$store.commit('setIsSubscriber', false);
        this.refreshToken();
        this.$router.push({ name: 'Default' });
        return null;
      }
      return res.request_info.success ? res : null;
    },
    makeRainforestPagination(rf) {
      // rainforest検索時のPagination
      const pagination = rf.pagination;
      if (pagination == void 0) {
        this.rfpagination.lastpage = 1;
        return;
      }
      // 最大ページ数は正確でない
      this.rfpagination.lastpage = Math.max(pagination.total_pages, pagination.current_page);
    },
    loaded() {
      this.disabledAiAnalysis = false;
    },
    async aianalysis() {
      const message = AIChat.createMessageFromReviewResearch(this.product.title, this.reviews.map(item => item.body));
      this.$refs.aichat.show(message, true);
    }
  },
}
</script>

<style scoped>
.ratingpane {
  width: 160px;
  border: 1px solid #ccc;
  padding-top: 4px;
  background-color: white;
}

.productinfo {
  font-size: 10pt;
}

.count {
  font-size: 8pt;
  font-weight: bold;
  margin-top: 8px;
  margin-left: 30px;
}

.rating {
  width: 110px;
  padding: 0px;
  border-width: 0px;
  background-color: transparent;
}

.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

@media (max-width: 575.98px) {
  .ratingpane {
    width: 130px;
    padding-top: 6px;
  }
}
</style>
